/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTrackingReady } from '../../redux';
import { ANALYTICS_ID, CONVERSION_ID } from '../../services/analytics';

// hook para descargar la lib de google analytics con un retraso de 5s
export default function useGoogleAnalytics() {
   const dispatch = useDispatch();
   const [loadTime, setLoadTime] = useState(null);

   // calculamos el tiempo que tardo en cargar la app
   useEffect(() => {
      const time = Date.now() - window.performance.timing.navigationStart;
      setLoadTime(time);
   }, []);

   useEffect(() => {
      // verificamos si no esta cargada la lib
      if ((window.gtag === undefined || !loadTime) && ANALYTICS_ID) {
         // iniciar settimeout de 5s
         const id = setTimeout(() => {
            // crear etiqueta <script> para que empiece a descargar
            const url = `https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_ID}`;
            const s = document.createElement('script');
            s.setAttribute('src', url);
            document.body.appendChild(s);

            // creamos variable necesaria para analytics
            window.dataLayer = window.dataLayer || [];

            // funcion helper gtag
            window.gtag = function () {
               window.dataLayer.push(arguments);
            };

            // iniciamos la lib
            window.gtag('js', new Date());

            // vinculamos el ID y le ponemos false al send_page_view
            // para evitar que se envie automaticamente un evento de pageview
            window.gtag('config', ANALYTICS_ID, {
               send_page_view: false,
            });

            // vinculamos a la cuenta de Google Ads pasando el ID de conversion
            // y agregamos la prop allow_enhanced_conversions, para habilitar conversiones mejoradas
            if (CONVERSION_ID) {
               window.gtag('config', CONVERSION_ID, { allow_enhanced_conversions: true });
            }

            // enviamos el evento de cuanto tardo en cargar la app
            window.gtag('event', 'timing_complete', {
               name: 'load',
               value: loadTime,
            });

            // indicamos que google analytics ya se cargo
            dispatch(setTrackingReady('analytics'));
         }, 5000);

         return () => {
            clearTimeout(id);
         };
      }

      return () => {};
   }, [dispatch, loadTime]);
}
